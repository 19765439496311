import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css";

import javs from '../assets/img/js.png';
import reactN from '../assets/img/react.png';
import java from '../assets/img/java.png';
import db from '../assets/img/firebase.png';
import ans from '../assets/img/as.png';

import proj1_0 from '../assets/img/proj1-0.png';
import proj1_1 from '../assets/img/proj1-1.png';
import proj2_0 from '../assets/img/proj2-0.png';
import proj2_1 from '../assets/img/proj2-1.png';
import proj3_0 from '../assets/img/proj3-0.png';
import proj3_1 from '../assets/img/proj3-1.png';

export const Projects = () => {

  const details = [
    {
      id: 1,
      title: 'SaveLives; Blood Donation Android Application',
      description: 'An Android Application for Blood Recipients to find relevant Blood Donors by the Blood Group and the location, allow them to get contact details and Donor get rewarded when Donate Blood.',
      img1: proj1_0,
      img2: proj1_1,
      lang1: 'Java', lang1Href: 'https://www.w3schools.com/java/', lang1Img: java,
      lang2: 'Android Studio', lang2Href: 'https://developer.android.com/studio', lang2Img: ans,
      lang3: 'Firebase', lang3Href: 'https://firebase.google.com/docs', lang3Img: db,
      gitLink: 'https://github.com/nveen9/BloodDonationApp'
    },
    {
      id: 2,
      title: 'PawPaw; The Dogs Saving Mobile Application',
      description: 'A Mobile Application for help Stray and Missing Dogs, users able to find recent posts, search by location and description, find nearest pharmacies & veterinarians and allow them to get contact detail',
      img1: proj2_0,
      img2: proj2_1,
      lang1: 'JavaScript', lang1Href: 'https://www.w3schools.com/js/', lang1Img: javs,
      lang2: 'React Native', lang2Href: 'https://reactnative.dev/', lang2Img: reactN,
      lang3: 'Firebase', lang3Href: 'https://firebase.google.com/docs', lang3Img: db,
      gitLink: 'https://github.com/nveen9/dogSocialApp'
    },
    {
      id: 3,
      title: 'DreamDay; Wedding Planning Android Application',
      description: 'An Android Application for Users to find Wedding Service Providers and allow Users to Book and view the price, details, and the location.',
      img1: proj3_0,
      img2: proj3_1,
      lang1: 'Java', lang1Href: 'https://www.w3schools.com/java/', lang1Img: java,
      lang2: 'Android Studio', lang2Href: 'https://developer.android.com/studio', lang2Img: ans,
      lang3: 'Firebase', lang3Href: 'https://firebase.google.com/docs', lang3Img: db,
      gitLink: 'https://github.com/nveen9/DreamDayWeddingApp'
    },
  ];

  return (
    <section className="project" id="projects">
      <Container fluid={true} className="px-4">
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <br />
          </Col>
        </Row>
        <br />
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
          {details.map(({ id, title, description, img1, img2, lang1, lang1Href, lang1Img, lang2, lang2Href, lang2Img, lang3, lang3Href, lang3Img, gitLink }) => (
            <Row key={id}>
              {id % 2 === 0 ? (
                <>
                  <Col sm={6}>
                    <div className="projcard projcard-red">
                      <div className="projcard-textbox">
                        <h3>{title}</h3>
                        <div className="projcard-bar"></div>
                        <p>{description}</p>
                        <div className="projcard-usingitems">
                          <a href={lang1Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang1Img} alt="lang" />
                            <span className="itm">{lang1}</span>
                          </a>
                          <a href={lang2Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang2Img} alt="lang" />
                            <span className="itm">{lang2}</span>
                          </a>
                          <a href={lang3Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang3Img} alt="lang" />
                            <span className="itm">{lang3}</span>
                          </a>
                        </div>
                        <div className="projcard-git">
                          <a href={gitLink} target="_blank" rel="noopener noreferrer">GitHub</a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="projct-img">
                      <img
                        alt="First"
                        src={img1}
                      />
                      <img
                        alt="Second"
                        src={img2}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col sm={6}>
                    <div className="projct-img">
                      <img
                        alt="First"
                        src={img1}
                      />
                      <img
                        alt="Second"
                        src={img2}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="projcard projcard-red">
                      <div className="projcard-textbox">
                        <h3>{title}</h3>
                        <div className="projcard-bar"></div>
                        <p>{description}</p>
                        <div className="projcard-usingitems">
                          <a href={lang1Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang1Img} alt="lang" />
                            <span className="itm">{lang1}</span>
                          </a>
                          <a href={lang2Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang2Img} alt="lang" />
                            <span className="itm">{lang2}</span>
                          </a>
                          <a href={lang3Href} target="_blank" rel="noopener noreferrer">
                            <img src={lang3Img} alt="lang" />
                            <span className="itm">{lang3}</span>
                          </a>
                        </div>
                        <div className="projcard-git">
                          <a href={gitLink} target="_blank" rel="noopener noreferrer">GitHub</a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </AnimationOnScroll>
      </Container>
    </section>
  );
};
